import { Apihelper } from "./API/apiHelper.js";
const apiHelper = new Apihelper();
const baseUrl = process.env.VUE_APP_REGISTRATION_SERVER_URL;

export const generateOTP = (data) => {
    const uri = `${baseUrl}/api/v1/pre-register`;
    return apiHelper.post(uri, data);
}

export const generateOTPResend = (data) => {
    const uri = `${baseUrl}/api/v1/otp-resend`;
    return apiHelper.post(uri, data);
}

export const validateOTP = (data) => {
    const uri = `${baseUrl}/api/v1/validate-otp`;
    return apiHelper.post(uri, data);
}

export const register = (data) => {
    const uri = `${baseUrl}/api/v1/user-registration`;
    return apiHelper.post(uri, data);
}