<template>
  <div class="col-12 mb-3" style="background-color:white;">
    <!-- <Alert v-model="alert" :alertMsg="alertMsg" :isSuccess="isSuccess" /> -->
    <Alert v-if="alert" :alert="alertStyle" :alertMsg="alertMsg" :isSuccess="isSuccess" @closeAlert="closeAlertPopup"/>
    <div class="pt-4">
      <h3 class="head-label mt-2">
        Hello
        <span>Gaurav</span
        >, Welcome to D-Ecommerece
      </h3>
      <div class="verify-container">
        <div>
          <h4 class="secondry-head-label mt-4">
            We’ve sent your verification code to your email and phone number
          </h4>
          <h6 class="sub-header-label">
            You’ll receive code containing 6-digit verification code 
          </h6>
        </div>
        <div
          style="margin:0 auto;"
          class="col-md-10"
          v-if="!isVerificationSuccess"
        >
          <div class="form-row">
            <p class="text-center pt-5 icon-image">
              <img src="../../assets/Email_verify.svg" />
            </p>
            <div class="col-md-9 m-auto">
              <label class="code-label"
                >Type code that has been sent to your email</label
              >
              <ValidationProvider name="Code" rules>
                <div slot-scope="{ errors }">
                  <input
                    class="form-control code-input-class"
                    :name="'Code'"
                    placeholder
                    :value="emailVerificationCode"
                    @keypress="validateNumber($event)"
                    @input="validateLength($event)"
                  />
                  <p class="text-danger">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <p class="resend-label text-muted" v-if="!isVerificationSuccess">
                Didn’t receive the verification code yet ?
                <span
                  :style="
                    !reSending ? 'color:#184ed5;cursor:pointer' : 'color:grey;'
                  "
                  @click="!otpValidating && !reSending ? resendEmailCode() : ''"
                  >Resend Code</span
                >
              </p>
            </div>
          </div>
          <div class="form-row">
            <p class="text-center icon-image pt-5">
              <img src="../../assets/Phone_verify.svg" />
            </p>
            <div class="col-md-9 m-auto">
              <label class="code-label"
                >Type code that has been sent to your Phone number</label
              >
              <ValidationProvider name="Code" rules>
                <div slot-scope="{ errors }">
                  <input
                    class="form-control code-input-class"
                    :name="'Code'"
                    placeholder
                    :value="mobVerificationCode"
                    @keypress="validateNumber($event)"
                    @input="validateOTPLength($event)"
                  />
                  <p class="text-danger">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
              <p class="resend-label text-muted" v-if="!isVerificationSuccess">
                Didn’t receive the verification code yet ?
                <span
                  :style="
                    !reSending ? 'color:#184ed5;cursor:pointer' : 'color:grey;'
                  "
                  @click="!otpValidating && !reSending ? resendMobCode() : ''"
                  >Resend Code</span
                >
              </p>
            </div>
          </div>
        </div>
        <div class="form-row" v-else>
          <div class="col text-center">
            <b-button class="verified-button">
              <img src="../../assets/verified.svg" class="mr-2" />VERIFIED
            </b-button>
          </div>
        </div>
      </div>
      <div class="m-auto col-md-2 text-center">
        <b-button
          :key="'VERIFYBUTTON' + otpValidating"
          class="verify-button"
          @click="!otpValidating ? goToNextPage() : ''"
          :disabled="!validateForm || otpValidating"
          v-if="!isVerificationSuccess"
        >
          <i v-if="otpValidating" class="fas fa-circle-notch fa-spin"></i>
          <template v-else>VERIFY CODES</template>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { validateOTP, generateOTPResend } from "../../Service/RegistrationService";
import Alert from "../../widgets/Alert";

export default {
  components: {
    Alert,
  },
  data() {
    return {
      emailVerificationCode: "",
      mobVerificationCode: "",
      isVerificationSuccess: false,
      isVerificationCodeValid: false,
      otpValidating: false,
      alert: false,
      alertMsg: "Something Went wrong! Try again",
      isSuccess: false,
      reSending: false,
      alertStyle: "",
      // userType: "Enterprise",
      userInformation: ""
    };
  },
  computed: {
    validateForm() {
      return (
        this.emailVerificationCode != "" &&
        String(this.emailVerificationCode).length == 6 &&
        this.mobVerificationCode != "" &&
        String(this.mobVerificationCode).length == 6
      );
    },
  },
  created() {
    // this.userType = localStorage.getItem("userType");
    this.userInformation = JSON.parse(localStorage.getItem("userInformation"))
  },
  methods: {
    goToNextPage() {
      this.toggleOtpValidating(true);
      let validateObj = {
        emailId : this.userInformation.email,
        mobileNumber : this.userInformation.phoneNumber,
        smsOtp : this.mobVerificationCode,
        emailOtp: this.emailVerificationCode
      }
      validateOTP( validateObj )
        .then((res) => {
          this.toggleOtpValidating(false);
          console.log("validate", res)
          if (res.status === "SUCCESS") {
            this.showAlert(res.response, true, "Success");

            // setTimeout(() => {
            //   this.$router.push({
            //     name: "Registration Verified",
            //     params: { email: this.userInformation.email },
            //   });
            // }, 3000)

            setTimeout(() => {
              this.$router.push("/register");
            }, 3000)

          } else {
            this.showAlert(res.message, true, "Warning");
          }
        })
        .catch((e) => {
          this.toggleOtpValidating(false);
          console.log(e);
          this.showAlert(e.message,false, "Failure");
        });
    },
    resendMobCode() {
      let userObj = {
        emailId: this.userInformation.email,
        mobileNumber: this.userInformation.phoneNumber,
        // dialCode: this.userInformation.dialCode,
        // types: ["mobile"],
      };
      this.resend(userObj);
    },
    resendEmailCode() {
      let userObj = {
        emailId: this.userInformation.email,
        mobileNumber: this.userInformation.phoneNumber,
        // types: ["email"],
      };
      this.resend(userObj);
    },
    resend(userObj) {
      this.reSending = true;
      generateOTPResend(userObj)
        .then((res) => {
          this.reSending = false;
          console.log("resend otp ", res)
          if (res.status === "SUCCESS") {
            this.showAlert(res.response, true, "Success");
          } else {
            this.showAlert(res.failureMessage, true, "Warning");
          }
        })
        .catch((e) => {
          console.log(e);
          this.reSending = false;
          this.showAlert(e.message, true, "Failure");
        });
    },
    validateLength(evt) {
      const value = evt.target.value;
      if (String(value).length <= 6) {
        this.emailVerificationCode = value;
      }
    },
    validateOTPLength(evt) {
      const value = evt.target.value;
      if (String(value).length <= 6) {
        this.mobVerificationCode = value;
      }
    },
    validateNumber(evt) {
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
      this.$forceUpdate();
    },
    toggleOtpValidating(currentState) {
      this.otpValidating = currentState;
    },
    showAlert(msg = "Something went wrong!", isSuccess = false, style) {
      this.alertMsg = msg;
      this.alert = true;
      this.isSuccess = isSuccess;
      this.alertStyle = style;
    },
    closeAlertPopup(){
      this.alert = false
    }
  },
};
</script>
<style scoped>
.code-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
.resend-label {
  font-family: "proxima-nova", sans-serif !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}
.code-input-class {
  text-align: center;
  font-weight: 700 !important;
  letter-spacing: 0.5em;
}
.verify-button {
  height: 40px !important;
  font-size: 13px;
  border-radius: 6px !important;
  background-color: rgb(69 117 193) !important;
  width: 250px !important;
  border-color: rgb(69 117 193) !important;
}
.disable {
  pointer-events: none;
  opacity: 0.5;
}
.icon-image {
  padding-top: 2.1rem !important;
}
@media (max-width: 506px) {
  .icon-image {
    margin: auto;
  }
}
.verify-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .verify-container {
    max-width: 600px !important;
  }
}
@media (max-width: 992px) {
  .verify-container {
    min-width: 250px !important;
  }
  .resend-label {
    font-size: 12px;
  }
}
</style>
